<template>
  <div
    class="role--list"
    v-loading="loading"
    element-loading-text="加载中，请稍候"
    element-loading-background="rgba(255,255,255,.9)"
  >
    <!-- 全部无结果 -->
    <div class="all-no-data" v-if="roleDao && roleDao.total === 0 && role_name === ''">
      <null-page nullType="nullData" text="暂未创建角色" :height="0">
        <vh-button type="primary" round v-preventReClick @click.prevent.stop="addRole">
          创建角色
        </vh-button>
      </null-page>
    </div>
    <!-- 全部有结果 -->
    <div class="all-yes-data" v-else>
      <!-- 搜索 -->
      <div class="role--list--search">
        <vh-button size="medium" type="primary" round @click.prevent.stop="addRole">
          创建角色
        </vh-button>
        <vh-button
          size="medium"
          round
          plain
          @click.prevent.stop="multiMsgDel"
          :disabled="!(this.checkRows && this.checkRows.length > 0)"
        >
          批量删除
        </vh-button>
        <vh-input
          placeholder="搜索角色名称"
          v-model="role_name"
          clearable
          round
          v-clearEmoij
          @input="checkoutList"
          @clear="initQuerySonList"
          class="search-query"
          @keyup.enter.native="initQuerySonList"
        >
          <i class="vh-icon-search vh-input__icon" slot="prefix" @click="initQuerySonList"></i>
        </vh-input>
      </div>
      <!-- 有消息内容 -->
      <div>
        <!-- 表格与分页 -->
        <table-list
          ref="roleTab"
          :isHandle="true"
          :manageTableData="roleDao.list"
          :tabelColumnLabel="roleTableColumn"
          :totalNum="roleDao.total"
          :tableRowBtnFun="tableRowBtnFun"
          :needPagination="true"
          width="114px"
          max-height="auto"
          scene="roleList"
          :pageLimit="query.limit"
          @getTableList="getRoleList"
          @changeTableCheckbox="checkMoreRow"
          @onHandleBtnClick="onHandleBtnClick"
        ></table-list>
      </div>
      <!-- 无消息内容 -->
      <null-page
        class="search-no-data"
        :height="0"
        v-if="roleDao && roleDao.total === 0"
      ></null-page>
    </div>
    <!-- 添加子账号 -->
    <VhallDialog
      width="600px"
      :visible.sync="roleDialogVisible"
      :title="roleForm.executeType === 'edit' ? '编辑角色' : '创建角色'"
      append-to-body
    >
      <vh-form
        :model="roleForm"
        ref="roleForm"
        class="createRole"
        :rules="roleFormRules"
        label-width="80px"
      >
        <vh-form-item label="角色名称" prop="role_name">
          <vh-input
            type="text"
            v-clearEmoij
            placeholder="请输入角色名称"
            autocomplete="off"
            v-model="roleForm.role_name"
            :maxlength="15"
            show-word-limit
          ></vh-input>
        </vh-form-item>
        <vh-form-item label="备注信息" prop="remark" class="remark--item">
          <vh-input
            type="text"
            v-clearEmoij
            placeholder="请输入备注信息"
            autocomplete="off"
            v-model="roleForm.remark"
            :maxlength="30"
            show-word-limit
          ></vh-input>
        </vh-form-item>
        <vh-form-item label="直播管理" prop="permission_webinar" class="switch--item">
          <div class="switch__box">
            <vh-checkbox
              v-model="roleForm.permission_webinar"
              :disabled="true"
              :true-label="1"
              :false-label="0"
            >
              创建、设置、发起直播，默认不可取消
            </vh-checkbox>
          </div>
        </vh-form-item>
        <vh-form-item label="回放管理" prop="permission_content" class="switch--item">
          <div class="switch__box">
            <vh-checkbox v-model="roleForm.permission_content" :true-label="1" :false-label="0">
              查看回放
            </vh-checkbox>
            <vh-checkbox
              v-model="roleForm.permission_content_download"
              :disabled="roleForm.permission_content == 0"
              :true-label="1"
              :false-label="0"
            >
              下载回放
            </vh-checkbox>
          </div>
        </vh-form-item>
        <vh-form-item label="数据管理" prop="permission_data" class="switch--item">
          <div class="switch__box">
            <div class="checkbox-warpper">
              <vh-checkbox v-model="roleForm.permission_data" :true-label="1" :false-label="0">
                查看数据
              </vh-checkbox>
            </div>
            <div class="checkbox-warpper">
              <vh-checkbox
                v-model="roleForm.permission_data_download"
                :disabled="roleForm.permission_data == 0"
                :true-label="1"
                :false-label="0"
              >
                下载可编辑数据
              </vh-checkbox>
              <div class="checkbox-warpper__help">
                <vh-tooltip
                  class="item"
                  effect="dark"
                  content="仅针对报名表单数据，格式为Excel"
                  placement="top"
                >
                  <i class="iconfont-v3 saasicon_help_m"></i>
                </vh-tooltip>
              </div>
            </div>
            <div class="checkbox-warpper" v-if="hasDownloadPdf">
              <vh-checkbox
                v-model="roleForm.permission_data_download_pdf"
                :disabled="roleForm.permission_data == 0"
                :true-label="1"
                :false-label="0"
              >
                下载PDF数据
              </vh-checkbox>
              <div class="checkbox-warpper__help">
                <vh-tooltip
                  class="item"
                  effect="dark"
                  content="PDF格式仅支持报名表单数据"
                  placement="top"
                >
                  <i class="iconfont-v3 saasicon_help_m"></i>
                </vh-tooltip>
              </div>
            </div>
          </div>
        </vh-form-item>
        <vh-form-item label="财务管理" prop="permission_fin" class="switch--item">
          <div class="switch__box">
            <vh-checkbox v-model="roleForm.permission_fin" :true-label="1" :false-label="0">
              查看财务信息
            </vh-checkbox>
          </div>
        </vh-form-item>
        <vh-form-item
          v-if="hasDownloadPdf || hasConsoleLogo"
          label="运营管理"
          prop="permission_fin"
          class="switch--item"
        >
          <div class="switch__box" v-if="hasDownloadPdf">
            <vh-checkbox
              v-model="roleForm.permission_operating_watermark"
              :true-label="1"
              :false-label="0"
            >
              下载文档水印
            </vh-checkbox>
            <vh-checkbox
              v-model="roleForm.permission_operating_watermark_parent"
              :true-label="1"
              :false-label="0"
            >
              使用主账号下载文档水印
            </vh-checkbox>
          </div>
          <div class="switch__box" v-if="hasConsoleLogo">
            <vh-checkbox
              v-model="roleForm.permission_setting_logo"
              :true-label="1"
              :false-label="0"
            >
              设置控制台logo
            </vh-checkbox>
            <vh-checkbox
              v-model="roleForm.permission_setting_use_parent_logo"
              :true-label="1"
              :false-label="0"
            >
              使用主账号控制台logo
            </vh-checkbox>
          </div>
        </vh-form-item>
      </vh-form>
      <span slot="footer" class="dialog-footer">
        <vh-button type="info" @click="roleDialogVisible = false" size="medium" plain round>
          取消
        </vh-button>
        <vh-button type="primary" @click.prevent.stop="executeRoleSend" size="medium" round>
          确定
        </vh-button>
      </span>
    </VhallDialog>
    <!-- 添加子账号 -->
  </div>
</template>

<script>
  import NullPage from '../../PlatformModule/Error/nullPage.vue';
  import { sessionOrLocal } from '@/utils/utils';

  export default {
    name: 'roleList.vue',
    components: {
      NullPage
    },
    data() {
      return {
        loading: false,
        query: {
          pos: 0,
          limit: 10,
          pageNumber: 1
        },
        role_name: '',
        roleDao: {
          total: 0,
          list: []
        },
        isHandle: false, // 是否有操作项
        roleTableColumn: [
          {
            label: '角色名称',
            key: 'role_name',
            width: 'auto'
          },
          {
            label: '关联账号',
            key: 'child_count',
            width: 200
          },
          {
            label: '备注',
            key: 'remark',
            width: 200
          }
        ],
        tableRowBtnFun: [
          {
            name: '编辑',
            methodName: 'roleEdit'
          },
          {
            name: '删除',
            methodName: 'roleDel'
          }
        ],
        ids: [],
        checkRows: [],
        roleDialogVisible: false,
        roleForm: {
          id: null,
          name: null,
          type: 'add',
          role_name: '',
          remark: '',
          permission_webinar: 1, // 直播管理开关 1开 0关闭
          permission_content: 1, // 回放管理开关 1开 0关闭
          permission_content_download: 1, // 回放管理下载回放开关 1开 0关闭
          permission_data: 1, // 数据管理 1开 0关闭
          permission_data_download: 1, // 数据管理下载可编辑数据 1开 0关闭
          permission_data_download_pdf: 1, // 数据管理下载 PDF 1开 0关闭
          permission_fin: 1, // 财务管理 1开 0关闭
          permission_operating_watermark: 1, // 下载文档水印 1开 0关闭
          permission_operating_watermark_parent: 0, // 使用主账号下载文档水印 1开 0关闭
          permission_setting_logo: 0, // 设置控制台logo
          permission_setting_use_parent_logo: 0 // 使用主账号控制台logo
        },
        reRoleForm: {
          permission_webinar: 1, // 直播管理开关 1开 0关闭
          permission_content: 1, // 回放管理开关 1开 0关闭
          permission_content_download: 1, // 回放管理下载回放开关 1开 0关闭
          permission_data: 1, // 数据管理 1开 0关闭
          permission_data_download: 1, // 数据管理下载可编辑数据 1开 0关闭
          permission_data_download_pdf: 1, // 数据管理下载 PDF 1开 0关闭
          permission_fin: 1, // 财务管理 1开 0关闭
          permission_operating_watermark: 1, // 下载文档水印 1开 0关闭
          permission_operating_watermark_parent: 0, // 使用主账号下载文档水印 1开 0关闭
          permission_setting_logo: 0, // 设置控制台logo
          permission_setting_use_parent_logo: 0 // 使用主账号控制台logo
        },
        roleFormRules: {
          role_name: [{ required: true, message: '请输入角色名称', trigger: 'blur' }]
        },

        // 是否需要显示下载pdf相关的设置：影响数据管理→下载pdf数据、运营管理→下载文档水印、使用主账号下载文档水印
        hasDownloadPdf: false,
        // 是否有控制台标识权限： 影响运营管理→设置控制台logo、使用主账号控制台logo
        hasConsoleLogo: false
      };
    },
    watch: {
      'roleForm.permission_content'(val) {
        if (val == 0) {
          this.roleForm.permission_content_download = 0;
        }
      },
      'roleForm.permission_data'(val) {
        if (val == 0) {
          this.roleForm.permission_data_download = 0;
          this.roleForm.permission_data_download_pdf = 0;
        }
      },
      'roleForm.permission_operating_watermark'(val) {
        if (val == 1) {
          this.roleForm.permission_operating_watermark_parent = 0;
        }
      },
      'roleForm.permission_operating_watermark_parent'(val) {
        if (val == 1) {
          this.roleForm.permission_operating_watermark = 0;
        }
      },
      'roleForm.permission_setting_logo'(val) {
        if (val == 1) {
          this.roleForm.permission_setting_use_parent_logo = 0;
        }
      },
      'roleForm.permission_setting_use_parent_logo'(val) {
        if (val == 1) {
          this.roleForm.permission_setting_logo = 0;
        }
      }
    },
    methods: {
      checkoutList(newValue) {
        if (!newValue) {
          this.initQuerySonList();
        }
      },
      // 表格操作列回调函数， val表示每行
      onHandleBtnClick(val) {
        let methodsCombin = this.$options.methods;
        methodsCombin[val.type](this, val);
      },
      // 批量选择
      checkMoreRow(val) {
        console.log('checkMoreRow', val);
        /*this.ids = val.map(item => {
        if (item.child_count > 0) {
          this.$vhAlert('当前角色已关联子账号，请先解绑关系后再进行删除', '提示', {
            confirmButtonText: '我知道了',
            lockScroll: false,
            customClass: 'zdy-alert-box'
          }).then(()=>{
          }).catch(()=>{});
        } else {
          return item.id;
        }
      });*/
        this.checkRows = val;
        console.log('结果复制', this.checkRows);
      },
      // 批量删除
      multiMsgDel() {
        if (!(this.checkRows && this.checkRows.length > 0)) {
          this.$vhMessage({
            message: `请至少选择一种角色删除`,
            showClose: true,
            // duration: 0,
            type: 'error',
            customClass: 'zdy-info-box'
          });
        } else {
          // 判断是否存在有角色已关联子账号
          let isRoleInclude = this.checkRows.filter(item => {
            return item.child_count > 0;
          });
          if (isRoleInclude && isRoleInclude.length > 0) {
            this.$vhAlert('当前角色已关联子账号，请先解绑关系后再进行删除', '提示', {
              confirmButtonText: '我知道了',
              customClass: 'zdy-alert-box',
              center: true,
              lockScroll: false
            })
              .then(() => {})
              .catch(() => {});
          } else {
            this.ids = this.checkRows.map(item => {
              return item.id;
            });
            this.deleteData(this.ids.join(','), 1);
          }
        }
      },
      deleteData(ids, index) {
        this.$vhConfirm('确定删除当前角色？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass: 'zdy-message-box',
          lockScroll: false,
          roundButton: true,

          cancelButtonClass: 'zdy-confirm-cancel'
        })
          .then(() => {
            this.$fetch('sonRoleDel', {
              ids: ids
            })
              .then(res => {
                this.$vhall_paas_port({
                  k: index == 1 ? 100829 : 100828,
                  data: {
                    business_uid: this.$parent.userId,
                    user_id: '',
                    webinar_id: '',
                    refer: '',
                    s: '',
                    report_extra: {},
                    ref_url: '',
                    req_url: ''
                  }
                });
                this.$vhMessage({
                  message: `删除成功`,
                  showClose: true,
                  // duration: 0,
                  type: 'success',
                  customClass: 'zdy-info-box'
                });
                this.ids = [];
                this.checkRows = [];
                try {
                  this.$refs.roleTab.clearSelect();
                } catch (e) {
                  console.log(e);
                }
                this.initComp();
              })
              .catch(res => {
                console.log(res);
                this.$vhMessage({
                  message: res.msg || '删除失败',
                  showClose: true,
                  // duration: 0,
                  type: 'error',
                  customClass: 'zdy-info-box'
                });
              });
          })
          .catch(() => {});
      },
      // 删除单条消息数据
      roleDel(that, { rows }) {
        if (rows.child_count > 0) {
          that
            .$vhAlert('当前角色已关联子账号，请先解绑关系后再进行删除', '提示', {
              confirmButtonText: '我知道了',
              customClass: 'zdy-alert-box',
              center: true,
              lockScroll: false
            })
            .then(() => {})
            .catch(() => {});
        } else {
          that.deleteData(rows.id, 2);
        }
      },
      // 编辑子账号
      async roleEdit(that, { rows }) {
        that.roleDialogVisible = true;
        that.roleForm.executeType = 'edit';
        that
          .$fetch('sonRoleGet', {
            id: rows.id
          })
          .then(res => {
            if (res.data) {
              console.log('--roleEdit res.data:', res.data);
              that.roleForm = Object.assign(that.roleForm, res.data);

              that.reRoleForm.permission_webinar = res.data.permission_webinar;
              that.reRoleForm.permission_content = res.data.permission_content;
              that.reRoleForm.permission_content_download = res.data.permission_content_download;

              that.reRoleForm.permission_data = res.data.permission_data;
              that.reRoleForm.permission_data_download = res.data.permission_data_download;
              that.reRoleForm.permission_data_download_pdf = res.data.permission_data_download_pdf;

              that.reRoleForm.permission_fin = res.data.permission_fin;

              /* prettier-ignore */
              that.reRoleForm.permission_operating_watermark = res.data.permission_operating_watermark;
              /* prettier-ignore */
              that.reRoleForm.permission_operating_watermark_parent = res.data.permission_operating_watermark_parent;

              that.reRoleForm.permission_setting_logo = res.data.permission_setting_logo;
              that.reRoleForm.permission_setting_use_parent_logo =
                res.data.permission_setting_use_parent_logo;
            }
          })
          .catch(res => {
            console.log(res);
            that.$vhMessage({
              message: res.msg || '获取角色信息失败',
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
          });
      },
      // 创建子账号
      addRole() {
        this.roleDialogVisible = true;
        this.roleForm.executeType = 'add';
        this.roleForm.id = null;
        this.$nextTick(() => {
          this.$refs.roleForm.resetFields();
          // 开关重置
          this.roleForm.permission_content = 1; // 内容管理开关 1开 0关闭
          this.roleForm.permission_content_download = 1;

          this.roleForm.permission_data = 1; // 数据管理 1开 0关闭
          this.roleForm.permission_data_download = 1;
          this.roleForm.permission_data_download_pdf = 1;

          this.roleForm.permission_fin = 1; // 财务管理 1开 0关闭

          this.roleForm.permission_operating_watermark = 1;
          this.roleForm.permission_operating_watermark_parent = 0;

          this.roleForm.permission_setting_logo = 0;
          this.roleForm.permission_setting_use_parent_logo = 0;
        });
      },
      // 保存子账号信息新增 or 修改
      executeRoleSend() {
        this.$refs.roleForm.validate(valid => {
          if (valid) {
            const params = JSON.parse(JSON.stringify(this.roleForm));
            if (!this.hasDownloadPdf) {
              // 配置未开，下面几项不修改
              delete params.permission_data_download_pdf;
              delete params.permission_operating_watermark;
              delete params.permission_operating_watermark_parent;
            }
            if (!this.hasConsoleLogo) {
              // 配置未开，下面几项不修改
              delete params.permission_setting_logo;
              delete params.permission_setting_use_parent_logo;
            }
            console.log('params:', params);
            this.$fetch(this.roleForm.executeType === 'add' ? 'sonRoleAdd' : 'sonRoleEdit', params)
              .then(res => {
                this.setReportData();
                this.$vhMessage({
                  message: `操作成功`,
                  showClose: true,
                  // duration: 0,
                  type: 'success',
                  customClass: 'zdy-info-box'
                });
                this.roleDialogVisible = false;
                this.initComp();
              })
              .catch(res => {
                console.log(res);
                this.$vhMessage({
                  message: res.msg || '操作失败',
                  showClose: true,
                  // duration: 0,
                  type: 'error',
                  customClass: 'zdy-info-box'
                });
              });
          }
        });
      },
      setReportData() {
        if (this.roleForm.executeType === 'add') {
          this.$vhall_paas_port({
            k: 100823,
            data: {
              business_uid: this.$parent.userId,
              user_id: '',
              webinar_id: '',
              refer: '',
              s: '',
              report_extra: {},
              ref_url: '',
              req_url: ''
            }
          });
          this.$vhall_paas_port({
            k: this.roleForm.permission_content ? 100825 : 100824,
            data: {
              business_uid: this.$parent.userId,
              user_id: '',
              webinar_id: '',
              refer: '',
              s: '',
              report_extra: {},
              ref_url: '',
              req_url: ''
            }
          });
          this.$vhall_paas_port({
            k: this.roleForm.permission_data ? 100827 : 100826,
            data: {
              business_uid: this.$parent.userId,
              user_id: '',
              webinar_id: '',
              refer: '',
              s: '',
              report_extra: {},
              ref_url: '',
              req_url: ''
            }
          });
        } else {
          if (this.roleForm.permission_content !== this.reRoleForm.permission_content) {
            this.$vhall_paas_port({
              k: this.roleForm.permission_content ? 100825 : 100824,
              data: {
                business_uid: this.$parent.userId,
                user_id: '',
                webinar_id: '',
                refer: '',
                s: '',
                report_extra: {},
                ref_url: '',
                req_url: ''
              }
            });
          }
          if (this.roleForm.permission_data !== this.reRoleForm.permission_data) {
            this.$vhall_paas_port({
              k: this.roleForm.permission_data ? 100827 : 100826,
              data: {
                business_uid: this.$parent.userId,
                user_id: '',
                webinar_id: '',
                refer: '',
                s: '',
                report_extra: {},
                ref_url: '',
                req_url: ''
              }
            });
          }
        }
      },
      // 获取列表数据
      getRoleList(row) {
        if (row) {
          this.query.pos = row.pos;
          this.query.pageNumber = row.pageNum;
          this.query.limit = row.limit;
        }
        this.loading = true;
        this.$fetch('sonRoleList', {
          role_name: this.role_name,
          pos: this.query.pos,
          limit: this.query.limit
        })
          .then(res => {
            this.loading = false;
            this.roleDao =
              res && res.code === 200 && res.data
                ? res.data
                : {
                    total: 0,
                    list: []
                  };
          })
          .catch(e => {
            this.loading = false;
            console.log(e);
            this.roleDao = {
              total: 0,
              list: []
            };
          });
      },
      initComp() {
        this.initQuerySonList();
      },
      initQuerySonList() {
        this.query.pos = 0;
        this.query.pageNumber = 1;
        // this.query.limit = 10;
        if (this.role_name) {
          this.$vhall_paas_port({
            k: 100830,
            data: {
              business_uid: this.$parent.userId,
              user_id: '',
              webinar_id: '',
              refer: '',
              s: '',
              report_extra: {},
              ref_url: '',
              req_url: ''
            }
          });
        }
        // 表格切换到第一页
        try {
          this.$refs.roleTab && (this.$refs.roleTab.pageInfo.pageNum = 1);
          this.$refs.roleTab && (this.$refs.roleTab.pageInfo.pos = 0);
        } catch (e) {
          console.log(e);
        }
        this.getRoleList();
      }
    },
    created() {
      const SAAS_VS_PES = sessionOrLocal.get('SAAS_VS_PES', 'localStorage');
      const permissions = JSON.parse(SAAS_VS_PES || null);
      this.hasDownloadPdf = !!permissions?.['download_pdf'];
      this.hasConsoleLogo = !!permissions?.['ui.console_logo'];
    }
  };
</script>
<style lang="less" scoped>
  .role--list {
    min-height: 292px;
  }
  .all-no-data {
    /* 基于外边框已经有距离： padding: 24px 24px 40px 24px; */
    padding-top: 30px;
    margin-top: 164px;
    .createActive {
      padding-bottom: 30px;
    }
  }
  .all-yes-data {
    padding: 24px 24px 40px 24px;
  }
  .search-no-data {
    padding-top: 148px;
    ::v-deep .search {
      padding-bottom: 0;
    }
  }

  .role--list--search {
    margin-bottom: 20px;
    .vh-select {
      float: right;
      margin-right: 20px;
    }
    .search-query.vh-input {
      width: 220px;
      float: right;
    }
  }

  .checkbox-warpper {
    display: inline-block;
    margin-right: 24px;

    &__help {
      line-height: 40px;
      padding-left: 3px;
      display: inline-block;

      .saasicon_help_m {
        vertical-align: bottom;
      }
    }
  }
</style>
<style lang="less">
  .createRole {
    .leve3_title {
      margin-left: 5px;
    }
  }
</style>
